import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, makeStyles } from '@material-ui/core';
import { Url, Phone, Email, FormTextField } from '@forager/bits';
import { useAuth0, useForagerNotification } from '@forager/client-utils';
import OverflowInput from '../../../components/OverflowInput';
import RejectCarrier from './RejectCarrier';
import ApproveCarrier from './ApproveCarrier';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ComplianceDetails = ({ values, carrierData, removeCarrierById }) => {
  const { accessToken } = useAuth0();
  const { error } = useForagerNotification();
  const {
    legalName,
    dbaName,
    address1,
    address2,
    cityName,
    stateShortName,
    postalCode,
    website,
    contacts,
    applicationId,
  } = carrierData;
  const classes = useStyles();

  const { firstName, lastName, phone, phoneExt, email } = contacts.find(
    // 3 types of contacts (1- Applicant, 2- Main, 3- Accounting)
    contact => contact.contactTypeId === 1 || contact.contactTypeId === 2
  );
  return (
    <Box padding="30px">
      <Grid
        container
        spacing={4}
        justify="space-between"
        data-testid="compliance-details"
      >
        <Grid lg={3} item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Company Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <OverflowInput title="Legal Name" value={legalName} />
          </Grid>
          <Grid item xs={12}>
            <OverflowInput title="DBA Name" value={dbaName || '-'} />
          </Grid>
          <Grid item xs={12} wrap="nowrap">
            <OverflowInput title="Address" value={address1} />
          </Grid>
          <Grid item xs={12}>
            <OverflowInput
              title="Suite/Ind. Park/Col."
              value={address2 || '-'}
            />
          </Grid>
          <Grid item xs={12} wrap="nowrap">
            <OverflowInput title="City" value={cityName} />
          </Grid>
          <Grid item xs={6} wrap="nowrap">
            <OverflowInput title="State/Province" value={stateShortName} />
          </Grid>
          <Grid item xs={6} wrap="nowrap">
            <OverflowInput title="Postal Code" value={postalCode} />
          </Grid>
          <Grid item xs={12} wrap="nowrap">
            <Url withCopy={!!website} label="Website" value={website || '-'} />
          </Grid>
        </Grid>
        <Grid lg={2} item container spacing={2} alignContent="flex-start">
          <Grid item xs={12}>
            <Typography variant="h5">Contact</Typography>
          </Grid>
          <Grid item xs={12}>
            <OverflowInput title="First Name" value={firstName} />
          </Grid>
          <Grid item xs={12}>
            <OverflowInput title="Last Name" value={lastName} />
          </Grid>
          <Grid item xs={12}>
            <Phone
              data-testid="compliance-office-phone"
              shrink
              extLabel="Ext."
              label="Office Phone"
              value={phone}
              extValue={phoneExt}
            />
          </Grid>
          <Grid item xs={12}>
            <Email
              data-testid="company-email"
              label="Email"
              value={email}
              className={classes.email}
            />
          </Grid>
        </Grid>

        <Grid lg={2} item container alignContent="flex-start">
          <Grid
            item
            xs={12}
            container
            direction="column"
            justify="flex-start"
            spacing={2}
          >
            <Grid item>
              <FormTextField fullWidth name="rfcNumber" label="RFC #" />
            </Grid>
            <Grid item>
              <FormTextField fullWidth name="sctNumber" label="SCT #" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="flex-end" spacing={2}>
        <RejectCarrier
          carrierData={carrierData}
          applicationId={applicationId}
          accessToken={accessToken}
          removeCarrierById={removeCarrierById}
          error={error}
        />
        <ApproveCarrier />
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  email: {
    justifyContent: 'flex-start',
  },
  check: {
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  label: {
    '& span.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
}));

ComplianceDetails.propTypes = {
  values: PropTypes.shape({}),
  applicationId: PropTypes.number,
  legalName: PropTypes.string,
  dbaName: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  cityName: PropTypes.string,
  stateShortName: PropTypes.string,
  postalCode: PropTypes.string,
  website: PropTypes.string,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      phoneExt: PropTypes.string,
      email: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  references: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      title: PropTypes.string,
      phone: PropTypes.string,
      phoneExt: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

const schema = Yup.object().shape({
  rfcNumber: Yup.string()
    .min(12, 'Should be at least 12 characters long')
    .max(13, 'Should be no more than 13 characters long')
    .required('Carriers must have valid RFC Number')
    .trim(),
  sctNumber: Yup.string()
    .max(40, 'Should be no more than 80 characters')
    .required('Carriers must have valid SCT Number')
    .trim(),
});

const handleSubmit = async (values, { props }) => {
  const { removeCarrierById, accessToken } = props;
  const {
    carrierData: { tableData, ...carrierData },
    ...restValues
  } = values;
  const { applicationId } = carrierData;
  await axios.put(
    `${process.env.REACT_APP_API_URL}/v1/carrierApplications/${applicationId}`,
    {
      ...carrierData,
      ...restValues,
      approved: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  removeCarrierById(applicationId);
};

export default withFormik({
  mapPropsToValues: ({ removeCarrierById, accessToken, tableData, ...rest }) =>
    rest,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: schema,
  handleSubmit,
})(ComplianceDetails);
