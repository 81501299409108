import React from 'react';
import { stringSortCompare, dateSortCompare } from '@forager/client-utils';
import { OverflowTooltip } from '@forager/bits';
import moment from 'moment-timezone';

const columns = [
  {
    title: 'Carrier Name',
    field: 'legalName',
    render: ({ legalName, dbaName }) => (
      <OverflowTooltip
        data-testid="carrier-tooltip"
        displayValue={`${dbaName || legalName}`}
        tooltipValue={`${dbaName || legalName}`}
      />
    ),
    customSort: (a, b) => {
      const aVal = a.dbaName || a.legalName;
      const bVal = b.dbaName || b.legalName;

      return stringSortCompare(aVal, bVal);
    },
    customFilterAndSearch: (term, { dbaName, legalName }) =>
      (dbaName || legalName).toLowerCase().includes(term.toLowerCase()),
  },
  {
    title: 'SCT #',
    field: 'sctNumber',
    render: ({ sctNumber }) => sctNumber || '—',
  },
  {
    title: 'RFC #',
    field: 'rfcNumber',
    render: ({ rfcNumber }) => rfcNumber || '—',
  },
  {
    title: 'Remit To',
    field: 'remitTo',
    type: 'boolean',
    render: ({ remitTo }) => (remitTo ? 'Yes' : 'No'),
  },
  {
    title: 'Created At',
    field: 'createdAt',
    type: 'datetime',
    customSort: (a, b) => dateSortCompare(a.createdAt, b.createdAt),
    render: ({ createdAt }) => moment(createdAt).format('MM/DD/YYYY HH:mm'),
  },
];

export default columns;
